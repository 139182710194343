import { Link, useLocation, useParams } from "react-router-dom"
import { Footer, Header } from "../../Sections"
import "./NewCaseStudy.css"
import axios from "axios"
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"

const NewCaseStudy = () => {
    const params = useParams()
    const location = useLocation()
    const [caseStudyId, setCaseStudyId] = useState(params.id)
    const [details, setDetails] = useState()
    const { t, i18n } = useTranslation()
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])
    useEffect(() => {
        axios.post("https://api.al-jabriya.com/api/show/case_student", {
            caseStudent_uuid: caseStudyId
        }, {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                setDetails(res.data.data.caseStudent)
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
    }, [caseStudyId, i18n.language])
    return (
        <>
            <Header />

            <div className={`link-project-con ${i18n.language == "ar" && "ar"}`}>
                <p className={`link link-project ${i18n.language == "ar" && "ar"}`}><Link to="/">{t("Home")}</Link>  / <Link to="/Casestudy">{t("casestudy")}</Link>  / {details && details.title}</p>
            </div>
            <div className={`hero-new-case ${i18n.language == "ar" && "ar"}`}
                style={{
                    backgroundImage: `url(${details && details.image})`
                }}
            ></div>
            <div className={`container-fluid about-case-study ${i18n.language == "ar" && "ar"}`}>
                <div className="row">
                    <div className="col-12 col-md-5">
                        <h5>{details && details.title}</h5>
                        <h6>   {details && details.partial_description}</h6>
                        <p className="first-p">
                            {details && details.description}
                        </p>
                    </div>
                    <div className="col-12 col-md-7">
                        {details && details.row_1_right_image != null ? <div className="img"
                            style={{
                                backgroundImage: `url(${details && details.row_1_right_image})`
                            }} ></div> : <div dangerouslySetInnerHTML={{ __html: details && details.row_1_right }} />}
                        {/* <h5>the challenges</h5>
                        <p>In the blue sky of 1977, a journey took ﬂight, not just across continents but  through the corridors of timeless style. The visionary founder of Al Jabriya, Mr. Hassan Asfour, initiated it all when he, high above the clouds chanced upon a brochure that would shape the destiny of generations to come.</p>
                        <p>In the blue sky of 1977, a journey took ﬂight, not just across continents but  through the corridors of timeless style. The visionary founder of Al Jabriya, Mr. Hassan Asfour, initiated it all when he, high above the clouds chanced upon a brochure that would shape the destiny of generations to come.</p>
                        <p>In the blue sky of 1977, a journey took ﬂight, not just across continents but  through the corridors of timeless style. The visionary founder of Al Jabriya, Mr. Hassan Asfour, initiated it all when he, high above the clouds chanced upon a brochure that would shape the destiny of generations to come.</p> */}
                    </div>
                </div>
            </div>
            <div className={`container-fluid about-case-study about-case-study-2 ${i18n.language == "ar" && "ar"}`}>
                <div className="row ">
                    <div className="col-12 col-md-5">
                        {details && details.row_2_left_image != null ? <div className="img"
                            style={{
                                backgroundImage: `url(${details && details.row_2_left_image})`
                            }} ></div> : <div dangerouslySetInnerHTML={{ __html: details && details.row_2_left }} />}
                        {/* <div className="img"></div> */}
                        {/* <h5>the challenges</h5>
                        <p>In the blue sky of 1977, a journey took ﬂight, not just across continents but  through the corridors of timeless style. The visionary founder of Al Jabriya, Mr. Hassan Asfour, initiated it all when he, high above the clouds chanced upon a brochure that would shape the destiny of generations to come.</p>
                        <p>In the blue sky of 1977, a journey took ﬂight, not just across continents but  through the corridors of timeless style. The visionary founder of Al Jabriya, Mr. Hassan Asfour, initiated it all when he, high above the clouds chanced upon a brochure that would shape the destiny of generations to come.</p>
                        <p>In the blue sky of 1977, a journey took ﬂight, not just across continents but  through the corridors of timeless style. The visionary founder of Al Jabriya, Mr. Hassan Asfour, initiated it all when he, high above the clouds chanced upon a brochure that would shape the destiny of generations to come.
                        </p> */}
                    </div>
                    <div className="col-12 col-md-7">
                        {details && details.row_2_right_image != null ? <div className="img"
                            style={{
                                backgroundImage: `url(${details && details.row_2_right_image})`
                            }} ></div> : <div dangerouslySetInnerHTML={{ __html: details && details.row_2_right }} />}
                        {/* <div className="img"></div> */}
                    </div>
                </div>
            </div>
            {details && details.row_3_image != null && <div className="hero-new-case mt-0"
                style={{
                    backgroundImage: `url(${details && details.row_3_image})`
                }}></div>}

            <div className={`container-fluid about-case-study about-case-study-2 ${i18n.language == "ar" && "ar"}`}>
                <div className="row">
                    <div className="col-12 col-md-5">
                        {details && details.row_4_left_image != null && <div className="img"
                            style={{
                                backgroundImage: `url(${details && details.row_4_left_image})`
                            }} ></div>}
                        {/* <div className="img"></div> */}
                    </div>
                    <div className="col-12 col-md-7">
                        {details && details.row_4_right_image != null ? <div className="img"
                            style={{
                                backgroundImage: `url(${details && details.row_4_right_image})`
                            }} ></div> : <div dangerouslySetInnerHTML={{ __html: details && details.row_4_right }} />}
                    </div>

                </div>
            </div>
            <Footer background="none" />
        </>
    )
}

export default NewCaseStudy