import "./AboutHero.css"
import AboutImg from "../../../Assets/Images/Rectangle 16.png"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
const AboutHero = () => {
    var hugeScreen = window.matchMedia("(min-width:3025px)")
    const { t, i18n } = useTranslation()
    return (
        <>
            <div className={`link-project-con ${i18n.language == "ar" && "ar"}`}>
                <p className={`link link-project ${i18n.language == "ar" && "ar"}`}><Link to="/">{t("Home")}</Link>  / {t("about")}  </p>

            </div>
            <div className={`container-fluid about-hero ${i18n.language == "ar" && "ar"}`}>
                <div className="row">
                    <div className="col-md-4">
                        <div className="img">
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="text">
                            <h2>{t("legacyBorn")}</h2>
                            <p>{t("aboutDesc")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutHero