import { useNavigate } from "react-router-dom"
import "./DesignJabyriah.css"
import { useTranslation } from "react-i18next"

const DesignJabyriah = () => {
    const navigate = useNavigate()
    var hugeScreen = window.matchMedia("(min-width:3025px)")
    const { t, i18n } = useTranslation()
    const handleNavigate = () => {
        navigate("/projects", {
            state: {
                uuid: "84437acb-4e42-43eb-8310-08f4416b430c",
                title: "Design"
            }
        })
    }
    return (
        <div className={`container-fluid design-jabyriah ${i18n.language == "ar" && "ar"}`}>
            <div className={"row"}>
                <div className="col-md-6">
                    <div className="text" onClick={handleNavigate} style={{
                        cursor: "pointer"
                    }}>
                        <h2>{t("ExplorealJabriya")} <svg
                            style={{
                                cursor: "pointer"
                            }}

                            xmlns="http://www.w3.org/2000/svg" width="38" height="68" viewBox="0 0 38 68" fill="none">
                            <path d="M1.5 67L36.5 34L1.5 0.999996" stroke="#434485" stroke-width="2" />
                        </svg></h2>
                    </div>

                </div>
                <div className="col-md-6"></div>
            </div>
        </div>
    )
}

export default DesignJabyriah