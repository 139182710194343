import { ClimbingBoxLoader } from "react-spinners"
import "./Loading.css"

const Loading = () => {
    return (
        <>
            <div className='container-fluid loading-con flex-aswad-center'>

            </div>
        </>
    )
}

export default Loading