import "./NewBrandsPage.css"
import { Footer, Header } from '../../Sections'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react"
import { useEffect, useRef, useState } from "react"
import axios from "axios"
import { Pagination } from "swiper/modules"
import { useTranslation } from "react-i18next"
const NewBrandsPage = () => {
    const ref = useRef()
    const [brands, setBrands] = useState()
    const [swiper, setSwiper] = useState([]);
    const { t, i18n } = useTranslation()
    const element = document.querySelectorAll("#brand");
    useEffect(() => {
        axios.get("https://api.al-jabriya.com/api/brand/all", {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                console.log(res)
                setBrands(res.data.data.brand)
            })
            .catch(err => {
                console.log(err)
            })
    }, [i18n.language])
    const handleNext = (swiper) => {
        ref.current.swiper.slideNext()
    };
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])

    // Function to handle previous slide
    const handlePrev = (index) => {
        swiper.slidePrev()
    };
    return (
        <>
            <Header />

            <div className={`link-project-con ${i18n.language == "ar" && "ar"}`}>
                <p className={`link link-project ${i18n.language == "ar" && "ar"}`}><Link to="/">{t("Home")}</Link>  / {t("brands")}</p>
            </div>
            <div className={`container-fluid logos-con bbb ${i18n.language == "ar" && "ar"}`}>
                <div className="row row-cols-3 row-cols-md-3 row-cols-lg-3 row-cols-xl-5">
                    {brands && brands.map((logo, i) => (
                        <>
                            {/* <div key={logo.uuid} className={`col col-logos ${i18n.language == "ar" && "ar"}`}>
                            <img src={logo.image} className="logo-brands-img logo-border" />
                        </div> */}
                            <div className={`col col-logos ${i18n.language == "ar" && "ar"}`}>
                                <div className="bordered-logo-con" onClick={() => element[i].scrollIntoView()}>
                                    <div style={{
                                        backgroundImage: `url(${logo.image})`
                                    }}></div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div >
            <div className={`container-fluid brands-con brands-new-con sss ${i18n.language == "ar" && "ar"}`}>
                <div className="text-con-brands">
                    <h2>{t("GlobalExcellence")}</h2>
                    <p>
                        {t("GlobalExcellenceDesc")}
                    </p>
                </div>
            </div>

            {
                brands && brands.map((brand, i) => (
                    <div>
                        <div id="brand" className={`container-fluid new-brands-con ${i18n.language == "ar" && "ar"}`}>
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                    <div className="cx-slider cx-sample-project container px-0">
                                        <div className="next" onClick={() => swiper[i].slideNext()}>
                                            <svg
                                                style={{
                                                    cursor: "pointer"
                                                }} xmlns="http://www.w3.org/2000/svg" width="40" height="77" color="#cccccc" viewBox="0 0 40 77" fill="none">
                                                <path d="M0.873047 75.9368L38.373 38.4368L0.873047 0.936768" stroke="#434485" stroke-width="2" />
                                            </svg>
                                        </div>
                                        <div className="prev" onClick={() => swiper[i].slidePrev()}>
                                            <svg
                                                style={{
                                                    cursor: "pointer"
                                                }}
                                                xmlns="http://www.w3.org/2000/svg" width="41" height="77" viewBox="0 0 41 77" fill="none">
                                                <path d="M39.5 0.936768L2 38.4368L39.5 75.9368" stroke="#434485" stroke-width="2" />
                                            </svg>
                                        </div>

                                        <Swiper
                                            onSwiper={(e) => setSwiper(prev => [...prev, e])}
                                            modules={[Pagination]}
                                            pagination={{ clickable: true }}
                                            spaceBetween={50}
                                            loop={true}
                                            slidesPerView={1}
                                        >
                                            {typeof (brand.gallery) == "object" && brand.gallery.map(item => (
                                                <SwiperSlide>
                                                    <div className="slide-img" style={{
                                                        backgroundImage: `url(${item})`
                                                    }}></div>
                                                </SwiperSlide>
                                            ))}

                                        </Swiper>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-lg-12 col-xl-6">
                                    <img src={brand.image} />
                                    <p>
                                        {brand.description}
                                    </p>
                                    <div class={`about-arrow-con ${i18n.language == "ar" && "ar"}`}><a href={brand.link} target="_blank">www.{brand.link.slice(8)}</a><svg xmlns="http://www.w3.org/2000/svg" width="38" height="87" viewBox="0 0 38 87" fill="none"><path d="M1 86L36.1333 43.5L1 1" stroke="#434485" stroke-width="2"></path></svg></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }

            <Footer background="none" />
        </>
    )
}

export default NewBrandsPage