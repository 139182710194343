import "./Slider.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md';
import { useRef } from "react";
const Slider = () => {
    const sliderRef = useRef()
    return (
        <>
            <div className="Sample-Img container-fluid">
                <div className="next" onClick={() => sliderRef.current.swiper.slideNext()}>
                    <MdKeyboardArrowRight size={100} className="icon" />
                </div>
                <div className="prev" onClick={() => sliderRef.current.swiper.slidePrev()}>
                    <MdKeyboardArrowLeft size={100} className="icon" />
                </div>
                <Swiper
                    ref={sliderRef}
                    modules={[Pagination]}
                    pagination={{ clickable: true }}
                    spaceBetween={50}
                    slidesPerView={1}
                >
                    <SwiperSlide>
                        <div className="slide-img"></div>
                    </SwiperSlide>
                    <SwiperSlide> <div className="slide-img"></div></SwiperSlide>
                    <SwiperSlide> <div className="slide-img"></div></SwiperSlide>

                </Swiper>
            </div>
        </>
    )
}

export default Slider