import { useCallback, useEffect } from "react"
import "./Hero.css"
import { motion, useAnimation } from "framer-motion"
import { useTranslation } from "react-i18next"
import Typist from 'react-typist';
const Hero = () => {
    const wAnimation = useAnimation()
    const hAnimation = useAnimation()
    const firstEAnimation = useAnimation()
    const rAnimation = useAnimation()
    const secondEAnimation = useAnimation()
    const dAnimation = useAnimation()
    const thirdEAnimation = useAnimation()
    const sAnimation = useAnimation()
    const iAnimation = useAnimation()
    const gAnimation = useAnimation()
    const nAnimation = useAnimation()
    const mAnimation = useAnimation()
    const fourthEAnimation = useAnimation()
    const fifthEAnimation = useAnimation()
    const tAnimation = useAnimation()
    const secondSAnimation = useAnimation()
    const sixEAnimation = useAnimation()
    const secondNAnimation = useAnimation()
    const secondGAnimation = useAnimation()
    const secondIAnimation = useAnimation()
    const thirdNAnimation = useAnimation()
    const sevenEAnimation = useAnimation()
    const eightEAnimation = useAnimation()
    const secondRAnimation = useAnimation()
    const thirdIAnimation = useAnimation()
    const FourthNAnimation = useAnimation()
    const thirdGAnimation = useAnimation()
    const thirdSAnimation = useAnimation()
    const fourthIAnimation = useAnimation()
    const fifthNAnimation = useAnimation()
    const cAnimation = useAnimation()
    const ninthEAnimation = useAnimation()
    const _1Animation = useAnimation()
    const _9Animation = useAnimation()
    const _7Animation = useAnimation()
    const _8Animation = useAnimation()
    const arrowAnimation = useAnimation()
    let mobile = window.matchMedia("(max-width:1024px)")
    const { t, i18n } = useTranslation()
    useEffect(() => {
        wAnimation.start({
            opacity: 1,
            transition: {
                duration: .1
            }
        })
        hAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: .1
            }
        })
        firstEAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: .2
            }
        })
        rAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: .3
            }
        })
        secondEAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: .4
            }
        })
        dAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: .5
            }
        })
        thirdEAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: .6
            }
        })
        sAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: .7
            }
        })
        iAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: .8
            }
        })
        gAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: .9
            }
        })
        nAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1
            }
        })
        mAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.1
            }
        })
        fourthEAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.2
            }
        })
        fifthEAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.3
            }
        })
        tAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.4
            }
        })
        secondSAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.5
            }
        })
        sixEAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.6
            }
        })
        secondNAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.7
            }
        })
        secondGAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.8
            }
        })
        secondIAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.9
            }
        })
        thirdNAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 2
            }
        })
        sevenEAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 2.1
            }
        })
        eightEAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 2.2
            }
        })
        secondRAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 2.3
            }
        })
        thirdIAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 2.4
            }
        })
        FourthNAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 2.5
            }
        })
        thirdGAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 2.6
            }
        })
        thirdSAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: .9
            }
        })
        fourthIAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1
            }
        })
        fifthNAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.2
            }
        })
        cAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.3
            }
        })
        ninthEAnimation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.4
            }
        })
        _1Animation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.5
            }
        })
        _9Animation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.6
            }
        })
        _7Animation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.7
            }
        })
        _8Animation.start({
            opacity: 1,
            transition: {
                duration: .1,
                delay: 1.8
            }
        })
        arrowAnimation.start({
            opacity: 1,
            transition: {
                duration: .5,
                delay: 2.6
            }
        })

    }, [])
    const whereDesign = useCallback(() => {
        if (i18n.language == "en") {
            return <>
                <Typist
                    key={1}
                    startDelay={200}
                    avgWpm={120}
                    onComplete={() => console.log('Typing completed!')}
                    cursor={{ show: false }}
                >
                    where design meets engineering
                </Typist>
            </>
        }
        else {
            return <>
                <Typist
                    key={2}
                    startDelay={200}
                    avgWpm={120}
                    onComplete={() => console.log('Typing completed!')}
                    cursor={{ show: false }}
                >
                    حيث يلتقي التصميم بالهندسة
                </Typist>
            </>
        }
    }, [i18n])

    const since = useCallback(() => {
        if (i18n.language == "en") {
            return <>
                <Typist
                    key={1}
                    startDelay={1800}
                    avgWpm={120}
                    onComplete={() => console.log('Typing completed!')}
                    cursor={{ show: false }}
                >
                    Since 1978
                </Typist>
            </>
        }
        else {
            return <>
                <Typist
                    key={2}
                    startDelay={1800}
                    avgWpm={120}
                    onComplete={() => console.log('Typing completed!')}
                    cursor={{ show: false }}
                >
                    منذ  1978
                </Typist>
            </>
        }
    }, [i18n])
    return (
        <>
            <div className={`container-fluid hero ${i18n.language == "ar" && "ar"}`}>

                <h1>
                    {whereDesign()}
                </h1>
                <p>


                    <span style={{
                        display: "inline-block"
                    }}>{since()}</span>
                </p>
                <motion.div
                    initial={{
                        opacity: 0
                    }}
                    animate={arrowAnimation}
                    className="one">
                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="26" viewBox="0 0 54 26" fill="none" className="one">
                        <path d="M2 2.39612L27 23.2325L52 2.39612" stroke="#CCCCCC" stroke-width="4" />
                    </svg>
                </motion.div>
                <motion.div
                    initial={{
                        opacity: 0
                    }}
                    animate={arrowAnimation}
                    className="two">
                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="26" viewBox="0 0 54 26" fill="none" >
                        <path d="M2 2.39612L27 23.2325L52 2.39612" stroke="#CCCCCC" stroke-width="4" />
                    </svg>
                </motion.div>
                <motion.div
                    initial={{
                        opacity: 0
                    }}
                    animate={arrowAnimation}
                    className="three">
                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="26" viewBox="0 0 54 26" fill="none" className="three">
                        <path d="M2 2.39612L27 23.2325L52 2.39612" stroke="#CCCCCC" stroke-width="4" />
                    </svg>
                </motion.div>
            </div>
        </>
    )
}

export default Hero