import { Footer, Header } from "../../Sections"
import "./Projects.css"
import { useEffect, useState } from "react"
import axios from "axios"
import { Loading } from "../../Components"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useAnimation, motion } from "framer-motion"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"

const Projects = () => {
    let mobile = window.matchMedia("(max-width:767px)")
    let ipad = window.matchMedia("(min-device-width: 768px) and (max-device-width: 1024px)")
    var hugeScreen = window.matchMedia("(min-width:3025px)")
    const navigate = useNavigate()
    const [projects, setProjects] = useState()
    const [categories, setCategories] = useState()
    const lis = document.querySelectorAll(".link-project-con li")
    const [all, setAll] = useState(true)
    const [uuid, setUuid] = useState()
    const projectsAnimation = useAnimation()
    const [status, setStatus] = useState(true)
    const { t, i18n } = useTranslation()
    const [categoryName, setCategoryName] = useState(null)
    const Location = useLocation()
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])
    useEffect(() => {
        if (Location?.state?.uuid) {
            setAll(false)
            lis.forEach(li => {
                if (li.innerHTML == Location?.state?.title) {
                    li.classList.add("active")
                }
                li.classList.remove("active")
            })

            // e.target.classList.add("active")
            setUuid(Location?.state?.uuid)
        }
    }, [Location.pathname])
    useEffect(() => {
        if (Location?.state?.uuid || uuid) {
            setStatus(true)
            axios.get(`https://api.al-jabriya.com/api/projects/${`all? category_uuid=${uuid}`}`, {
                headers: {
                    "Accept-Language": i18n.language
                }
            })
                .then(res => {
                    console.log(res)
                    setProjects(res.data.data.projects)
                    projectsAnimation.start({
                        y: 0,
                        opacity: 1,
                        transition: {
                            duration: .8
                        }
                    })
                    setStatus(false)

                })
                .catch(err => {
                    console.log(err)
                    setStatus(true)

                })
        }
        else {
            setStatus(true)
            axios.get(`https://api.al-jabriya.com/api/projects/all`, {
                headers: {
                    "Accept-Language": i18n.language
                }
            })
                .then(res => {
                    console.log(res)
                    setProjects(res.data.data.projects)
                    projectsAnimation.start({
                        y: 0,
                        opacity: 1,
                        transition: {
                            duration: .8
                        }
                    })
                    setStatus(false)

                })
                .catch(err => {
                    console.log(err)
                    setStatus(true)

                })
        }


    }, [uuid, i18n.language])
    useEffect(() => {
        axios.get("https://api.al-jabriya.com/api/categories/all", {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {

                setCategories(res.data.data.categories)

            })
            .catch(err => {
                console.log(err)

            })

    }, [i18n.language])
    const handleActive = (e, uuid) => {
        setAll(false)
        lis.forEach(li => {
            li.classList.remove("active")
        })
        e.target.classList.add("active")
        setUuid(uuid)
    }
    const handleAll = () => {
        setAll(true)
        lis.forEach((li) => {
            li.classList.remove("active")
        })
        setUuid(null)
    }
    const handleNavigate = (ID) => {
        navigate(`/Project/${ID}`, {
            state: {
                id: ID,
                uuid: uuid,
                categoryName: categoryName
            }
        })
    }

    return (
        <>
            <Header page="sample" />

            <div className={`link-project-con link-projectx-xxl-con ${i18n.language == "ar" && "ar"}`}>

                <p className={`link link-project link-projects-all-xll ${i18n.language == "ar" && "ar"}`}><Link to="/">{t("Home")}</Link>  / {t("projects")} </p>
                <ul>
                    <li className={all == true && "active"} onClick={handleAll}>{t("All")} </li>
                    {categories && categories.map(category => (
                        <li onClick={(e) => handleActive(e, category.uuid, category.title)} key={category.uuid} className={`${category.uuid == Location?.state?.uuid && "active"}`}>{category.title} </li>
                    ))}

                </ul>
            </div>
            <div className="BringingGreat-con-gre">
                {status == true ? <Loading /> : <></>}
                <div className={`container-fluid projects-con ${i18n.language == "ar" && "ar"}`} >
                    <motion.div
                        initial={{
                            y: 200,
                            opacity: 0
                        }}
                        animate={projectsAnimation}
                        className="row row-cols-2 row-cols-md-3 row-cols-lg-3" >
                        {projects && projects.map(project => (
                            <motion.div className="col" key={project.uuid} >
                                <div className="card-project" onClick={() => handleNavigate(project.uuid)}>
                                    <div className="img-card"
                                        style={{
                                            backgroundImage: `url(${project.image})`
                                        }}></div>
                                    <p>{project.title}</p>
                                </div>
                            </motion.div>
                        ))}


                    </motion.div>
                </div>
            </div>
            <Footer background="none" />
        </>
    )
}

export default Projects
{/* <div className="card-project">
<img src={Img} />
<p>zain</p>
</div> */}