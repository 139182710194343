import { useTranslation } from "react-i18next"
import "./SpacePlanning.css"

const SpacePlanning = () => {
    const { t, i18n } = useTranslation()
    return (
        <div className={`container-fluid space-planning-con ${i18n.language == "ar" && "ar"}`}>
            <div className="row row-cols-2 ">
                <div className="col-md-6">
                    <div className="space-planning-col">
                        <h2>{t("SpacePlanning")}</h2>
                        <p>{t("SpacePlaningDesc")}</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="space-planning-col">
                        <h2>{t("DesignConsultancy")}</h2>
                        <p>{t("DesignConsultancyDesc")}</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="space-planning-col">
                        <h2>{t("ProjectManagement")}</h2>
                        <p> {t("ProjectManagementDesc")}</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="space-planning-col m-last">
                        <h2>{t("TurnkeyProjects")}</h2>
                        <p>{t("TurnkeyProjectsDesc")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpacePlanning