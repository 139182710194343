import "./CaseStudy.css"
import { Header, Footer } from "../../Sections"
import { useEffect, useState } from "react"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import { Loading } from "../../Components"
import { useTranslation } from "react-i18next"
const CaseStudy = () => {
    const [caseStudy, SetCaseStudy] = useState()
    var hugeScreen = window.matchMedia("(min-width:3025px)")
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axios.get("https://api.al-jabriya.com/api/case_student/all", {
            headers: {
                "Accept-Language": i18n.language
            }
        })
            .then(res => {
                setLoading(false)
                SetCaseStudy(res.data.data.caseStudent)
            })
            .catch(err => {
                setLoading(true)
                console.log(err)
            })
    }, [i18n.language])
    const handleNavigate = (ID) => {
        navigate(`/CaseStudy/${ID}`, {
            state: {
                id: ID
            }
        })
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, [])
    return (
        <>
            {loading == true ? <Loading /> : <></>}
            <Header page="sample" />

            <div className={`link-project-con link-LFHO ${i18n.language == "ar" && "ar"}`}>
                <p className={`link link-project link-LFHO ${i18n.language == "ar" && "ar"}`}><Link to="/">{t("Home")}</Link>  / {t("casestudy")} </p>
            </div>
            {caseStudy && caseStudy.map((item, i, arr) => (
                <>
                    <div className={i == arr.length - 1 ? "container-fluid con-sm-case p-0 Advanced-Technology" : "container-fluid con-sm-case p-0"}>
                        <div className={`container-fluid LFHO ${i18n.language == "ar" && "ar"}`}>
                            <div className="row">
                                <div className={i % 2 != 0 ? "col-md-6 order-2 col-img-LFHO" : "col-md-6 col-img-LFHO"}
                                    style={{
                                        backgroundImage: `url(${item.image})`
                                    }}
                                >

                                </div>
                                <div className={i % 2 != 0 ? "col-md-6 order-1 col-img-text" : "col-md-6 col-img-text"}>
                                    <div className={i % 2 != 0 ? "text text-2" : "text"}>
                                        <h2>{item.title}</h2>
                                        <h3>{item.partial_description}</h3>
                                        <p>
                                            {item.description}
                                        </p>
                                        <div className="read-more-LFHO" onClick={() => handleNavigate(item.uuid)} style={{
                                            cursor: "pointer"
                                        }}>
                                            <h6 >{t("read")}</h6>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg" width="47" height="87" viewBox="0 0 47 87" fill="none">
                                                <path d="M1 86L46 43.5L1 1" stroke="#CCCCCC" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <hr />
                </>
            ))}
            {/* <div className="container con-sm-case p-0">
                <div className="container LFHO">
                    <div className="row">
                        <div className="col-md-6 col-img-LFHO">
                            <img src={Img} />
                        </div>
                        <div className="col-md-6 col-img-text">
                            <div className="text">
                                <h2>LFHO</h2>
                                <h3>Turnkey Project</h3>
                                <p>In the blue sky of 1977, a journey took ﬂight, not just across continents but  through the corridors of timeless style. The visionary founder of Al Jabriya, Mr. Hassan Asfour, initiated it all when he, high above the clouds chanced upon a brochure that would shape the destiny of generations to come.</p>
                                <div className="read-more-LFHO">
                                    <h6>Read More</h6>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="87" viewBox="0 0 47 87" fill="none">
                                        <path d="M1 86L46 43.5L1 1" stroke="#CCCCCC" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <hr />
            <div className="container p-0 con-sm-case">
                <div className="container LFHO">
                    <div className="row">
                        <div className="col-md-6 order-2 col-img-LFHO">
                            <img src={Img} />
                        </div>
                        <div className="col-md-6 order-1 col-img-text">
                            <div className="text text-2">
                                <h2>Wafra International<br />
                                    Investment Co.</h2>
                                <h3>Design Project</h3>
                                <p>In the blue sky of 1977, a journey took ﬂight, not just across continents but  through the corridors of timeless style. The visionary founder of Al Jabriya, Mr. Hassan Asfour, initiated it all when he, high above the clouds chanced upon a brochure that would shape the destiny of generations to come.</p>
                                <div className="read-more-LFHO">
                                    <h6>Read More</h6>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="87" viewBox="0 0 47 87" fill="none">
                                        <path d="M1 86L46 43.5L1 1" stroke="#CCCCCC" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <hr />
            <div className="container p-0 Advanced-Technology con-sm-case">
                <div className="container LFHO">
                    <div className="row">
                        <div className="col-md-6 col-img-LFHO">
                            <img src={Img} />
                        </div>
                        <div className="col-md-6 col-img-text">
                            <div className="text">
                                <h2>Advanced Technology<br />
                                    Company</h2>
                                <h3>Turnkey Project</h3>
                                <p>In the blue sky of 1977, a journey took ﬂight, not just across continents but  through the corridors of timeless style. The visionary founder of Al Jabriya, Mr. Hassan Asfour, initiated it all when he, high above the clouds chanced upon a brochure that would shape the destiny of generations to come.</p>
                                <div className="read-more-LFHO">
                                    <h6>Read More</h6>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="87" viewBox="0 0 47 87" fill="none">
                                        <path d="M1 86L46 43.5L1 1" stroke="#CCCCCC" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div> */}
            <Footer background="none" />

        </>
    )
}

export default CaseStudy