import { useTranslation } from "react-i18next"
import data from "../../Assets/Data/translationData"
import "./Translation.css"
import i18next from "i18next";
import { useCallback, useEffect } from "react";
const Translation = () => {
    const { i18n } = useTranslation()
    useEffect(() => {
        document.body.dir = i18n.language == "en" ? "ltr" : "rtl"
        // window.location.reload()
    }, [i18n.language])
    const li = useCallback(() => {
        if (i18n.language == "en") {
            return <li
                className="li-trans ar"
                style={{
                    cursor: "pointer"
                }}
                onClick={() => {
                    localStorage.setItem("lang", "ar")
                    window.location.reload()
                }}>
                عربي
            </li>
        }
        else {
            return <li
                className="li-trans"
                style={{
                    cursor: "pointer"
                }}
                onClick={() => {
                    localStorage.setItem("lang", "en")
                    window.location.reload()
                }}>
                English
            </li>
        }
    }, [i18n.language])
    return (
        <>
            {li()}
        </>
    )
}

export default Translation