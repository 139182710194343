import { useAnimation, motion } from "framer-motion"
import "./BringingGreat.css"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

const BringingGreat = () => {
    const animation = useAnimation()
    const opacity = useAnimation()
    const { t, i18n } = useTranslation()
    useEffect(() => {
        animation.start({
            y: 0,
            opacity: 1,
            transition: {
                duration: 1
            }
        })
    }, [])
    return (
        <>
            <div className={` bringing-great ${i18n.language == "ar" && "ar"}`}>
                <motion.div
                    initial={{
                        y: 1000,
                        opacity: 0
                    }}
                    animate={animation}
                    className="blur-animation-design"></motion.div>
                <motion.h2
                    initial={{
                        opacity: 0
                    }}
                    animate={animation}
                >{t("Bringing")}</motion.h2>
                <p>
                    {t("BringingDesc")}
                </p>
            </div>
        </>
    )
}

export default BringingGreat