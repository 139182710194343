import "./Footer.css"
import footerImg from "../../Assets/Images/NpbwA4.jpg"
import { Link } from "react-router-dom"
import { CiLocationOn } from "react-icons/ci";
import locationICon from "../../Assets/Images/location.png"
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const Footer = (props) => {
    const { t, i18n } = useTranslation()
    const bigScreens = window.matchMedia("(min-width:1540px)")
    const [status, setStatus] = useState(false)
    const [email, setEmail] = useState()
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    const handleSubscribtion = () => {
        axios.post("https://api.al-jabriya.com/api/add/subscribe", {
            email
        })
            .then(res => {
                console.log(res)
                setStatus(true)
            })
            .catch(err => {
                console.log(err)
            })
    }
    return (
        <>
            <div className={`footer container-fluid ${i18n.language == "ar" && "ar"}` + " " + props.background}>
                <div className="row row-footer">
                    <div className=" col-2 col-md-2  flex-aswad-center location-fo-col" >
                        <a className="location-icon-a" href="https://maps.app.goo.gl/Vgs3sZ7SgDEPtnHq7" target="_blank">
                            <img src={locationICon} className="location-icon" />
                            {t("location")}
                        </a>
                        {/* <div className="img">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1738.4410611886062!2d47.9886933!3d29.3737405!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fcf8f920235c0c3%3A0x8f7b065d3b7a0e65!2sAl-Jabriya%20Furniture%20%26%20Interiors%20Company!5e0!3m2!1sde!2s!4v1706381099782!5m2!1sde!2s" width="600" height="450" style={{
                                border: "0px"
                            }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div> */}
                    </div>
                    <div className="col-1 col-md-1  flex-aswad-center px-0" style={{
                        justifyContent: "flex-end"
                    }}>
                        <ul>
                            <li>
                                <Link to="/Design">
                                    {t("design")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/Fitout">
                                    {t("fitout")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/brands">
                                    {t("brands")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/Products">
                                    {t("products")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/Casestudy">
                                    {t("casestudy")}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-1 col-md-1  flex-aswad-center px-0" style={{
                        justifyContent: "flex-end"
                    }}>
                        <ul>
                            <li>
                                <Link to="/Projects">
                                    {t("projects")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/Blog">
                                    {t("blog")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/AboutUs">
                                    {t("about")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/Career">
                                    {t("career")}
                                </Link>
                            </li>
                            <li>
                                <Link to="/ContactUs">
                                    {t("contact")}
                                </Link>
                            </li>
                        </ul>
                    </div>


                    <div className="col-4 col-md-4 footer-small flex-aswad-center pr-0 fff" style={{
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        paddingTop: "3.5%",
                        paddingLeft: bigScreens.matches == false && i18n.language == "en" ? "6%" :
                            bigScreens.matches == true && i18n.language == "en" ? "10%" : "0%",
                        paddingRight: bigScreens.matches == false && i18n.language == "ar" ? "6%" :
                            bigScreens.matches == true && i18n.language == "ar" ? "10%" : "0%"

                    }}>
                        <p className="Subscribe">{t("Subscribe")}</p>
                        <div className="Subscribe-input">
                            <input type="text" placeholder={t("E-Mail")} onChange={(e) => setEmail(e.target.value)} />
                            <a onClick={handleSubscribtion}>{t("SubscribeBtn")}</a>
                        </div>
                        {status == true && <span>{t("Thank")}</span>}
                    </div>

                    <div className="col-3 col-md-3 follow">
                        <p className="follow-us">{t("Follow")}</p>
                        <div className="flex-aswad-center mb-5">
                            <a className="icon-con" target="_blank" href="https://www.facebook.com/AlJabriyaOfficeFurnitureInteriors?mibextid=LQQJ4d">
                                <svg width="16" height="30" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.17421 29.1255H10.7141V16.3058H14.8352L15.6459 11.1104H10.7141V7.19715C10.7141 5.78024 12.1328 4.90301 13.4165 4.90301H15.8486V0.584887L11.5248 0.382438C7.40367 0.112551 5.17421 3.35126 5.17421 7.19715V11.043H0.512695V16.2383H5.17421V29.1255Z" fill="white" />
                                </svg>
                            </a>
                            <a className="icon-con" target="_blank" href="https://www.instagram.com/aljabriyaco?igsh=eXB2N29ub2RmMDY0">
                                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.35467 0.434082H19.6504C23.5688 0.434082 26.8116 3.67263 26.8116 7.58598V19.7984C26.8116 23.7118 23.5688 26.9503 19.6504 26.9503H7.35467C3.43624 26.9503 0.19342 23.7118 0.19342 19.7984V7.58598C0.12586 3.67263 3.36868 0.434082 7.35467 0.434082ZM20.5962 5.08956C21.407 5.08956 22.1501 5.76431 22.1501 6.64145C22.1501 7.45111 21.4745 8.19333 20.5962 8.19333C19.718 8.19333 19.0424 7.51858 19.0424 6.64145C19.1099 5.76431 19.718 5.08956 20.5962 5.08956ZM13.435 6.439C17.421 6.439 20.7313 9.74508 20.7313 13.6584C20.7313 17.6393 17.421 20.8779 13.5025 20.8779H13.435C9.51656 20.8779 6.27374 17.6393 6.27374 13.6584C6.20618 9.74508 9.51656 6.439 13.435 6.439ZM13.435 8.93542C16.1374 8.93542 18.2992 11.0946 18.2992 13.726C18.2992 16.4248 16.1373 18.5839 13.5025 18.5839H13.435C10.8002 18.5839 8.63831 16.4248 8.63831 13.726C8.63831 11.0946 10.8002 8.93542 13.435 8.93542ZM7.35467 2.72805H19.5153C22.2852 2.72805 24.5147 4.95456 24.5147 7.7209V19.7984C24.5147 22.5648 22.2852 24.7913 19.5153 24.7913H7.35467C4.58475 24.7913 2.35528 22.5648 2.35528 19.7984V7.7209C2.42284 4.95456 4.65231 2.72805 7.35467 2.72805Z" fill="white" />
                                </svg>
                            </a>

                            <a className="icon-con" target="_blank" href="https://www.linkedin.com/company/al-jabriya-office-furniture-&-interiors/">
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.08966 8.67767H5.81881V24.6009H1.08966V8.67767ZM3.45424 0.648438C5.0081 0.648438 6.2917 1.93053 6.2917 3.48238C6.2917 5.03423 5.0081 6.31616 3.45424 6.31616C1.90038 6.31616 0.616699 5.03423 0.616699 3.48238C0.616699 1.93053 1.83282 0.648438 3.45424 0.648438ZM8.65627 8.67767H13.3179V10.9043C14.3313 9.41989 15.9527 8.47522 17.8443 8.47522H19.128C22.1006 8.47522 24.5327 10.9042 24.5327 13.873V16.302V19.1358V24.6009H19.8711V24.5335V23.1166V15.9647C19.8035 14.1429 18.5875 12.726 16.7634 12.726C14.9393 12.726 13.453 14.1429 13.3854 15.9647V22.172V24.6009H8.72381V8.67767H8.65627Z" fill="white" />
                                </svg>
                            </a>

                        </div>
                        <p className="copy">© {t("Copyright")} {currentYear}  {t("al-Jabriya")}</p>
                    </div>

                    <div className="col-1 col-md-1 logo-footer px-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="124" height="124" viewBox="0 0 124 124" fill="none">
                            <path d="M123.999 0.00683594H0.0429688V123.803H123.999V0.00683594Z" fill="#464380" />
                            <path d="M58.5752 76.5109H65.4749V79.3001C65.4749 86.9948 71.7421 93.2621 79.455 93.2621C87.1679 93.2621 93.435 87.003 93.435 79.3001V30.5314H85.64V23.6406H100.327V79.3001C100.327 90.8011 90.9627 100.153 79.455 100.153C67.9473 100.153 58.5752 90.8011 58.5752 79.3001V76.5109Z" fill="white" />
                        </svg>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer